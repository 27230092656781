#root .ql-container {
  border: none;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  border: none;
  border-bottom: 1px solid rgb(204, 204, 204, .5);
}

.edit-mode .ql-container {
  border-bottom: 1px solid rgb(204, 204, 204, .5) !important;
}

.edit-mode .ql-container > .ql-editor {
  min-height: 100px;
};